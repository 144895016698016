<template>
  <div>
    <b-card no-body>
      <!-- ใช้ใน Order SubProduct Member -->
      <div class="justify-content-between d-flex button-container">
        <h4>{{ title }}</h4>
      </div>
      <b-list-group flush>
        <b-list-group-item
          class="no-hover-bg"
          v-for="(x, index) in note_log_list"
          :key="index"
        >
          <div class="d-flex justify-content-between">
            <p class="mb-0">
              <span style="color: #ff5d03; font-size: 15px">{{ x.text }}</span>
              <br />
              <small>{{ toReadableDateWithGMT7(x.created_at) }}</small>
            </p>
            <div>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="startEditing(index)">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>

                <b-dropdown-item @click="confirmDelete(index)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <!-- edit input -->
          <div v-if="x.isEditing">
            <b-input-group>
              <b-form-input
                autocomplete="off"
                @keyup.enter="saveEdit(index)"
                v-model="x.editedNote"
              />
              <b-input-group-append>
                <b-button variant="outline-primary" @click="saveEdit(index)">
                  แก้ไข
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-list-group-item>
      </b-list-group>

      <div class="button-container">
        <b-input-group>
          <b-form-input
            placeholder="เพิ่ม Note"
            autocomplete="off"
            @keyup.enter="submitNote"
            v-model="note"
          />
          <b-input-group-append>
            <b-button variant="outline-primary" @click="submitNote">
              เพิ่ม
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BCard,
  BListGroup,
  BInputGroup,
  BListGroupItem,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import moment from "moment";
export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
    BListGroup,
    BListGroupItem,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  props: {
    update_id: {
      type: String,
      required: true, // Set to `false` if it is optional
    },
    location: {
      type: String,
      required: true, // Set to `false` if it is optional
    },
    title: {
      typee: String,
    },
  },
  data() {
    return {
      note: null,
      note_log_list: [],
    };
  },
  created() {
    this.load_data();
  },
  methods: {
    async load_data() {
      const response = await this.$http({
        method: "POST",
        url: `/log/note-log/fetch/`,
        data: {
          fetch_id: this.update_id,
          location: this.location,
        },
      });
      this.note_log_list = response.data.data.map((x) => ({
        ...x,
        isEditing: false,
        editedNote: x.text,
      }));
    },
    startEditing(index) {
      const item = this.note_log_list[index];
      item.isEditing = true;
    },
    // async editNote(index) {
    //   const item = this.note_log_list[index];
    //   item.text = item.editedNote; // Save the edited note
    //   item.isEditing = false;
    // },
    saveEdit(index) {
      const item = this.note_log_list[index];
      item.text = item.editedNote; // Save the edited note
      item.isEditing = false;
      this.$http({
        method: "POST",
        url: `/log/note-log/update`,
        data: {
          location: this.location,
          id: item._id,
          updated_note: item.text,
        },
      }).then((x) => {
        this.load_data();
      });
    },

    confirmDelete(index) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("ยืนยันการลบ !!!", {
          title: "ยืนยันการลบ",
          // size: 'sm',
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteNote(index);
          }
        });
    },

    deleteNote(index) {
      const item = this.note_log_list[index];
      this.$http({
        method: "POST",
        url: `/log/note-log/delete`,
        data: {
          location: this.location,
          id: item._id,
        },
      }).then((x) => {
        this.load_data();
      });
    },

    async submitNote() {
      if (!this.note.trim()) {
        alert("Please enter a note.");
        return;
      }

      try {
        await this.$http({
          method: "POST",
          url: `/log/note-log/add`,
          data: {
            update_id: this.update_id,
            location: this.location,
            note: this.note,
          },
        })
          .then((x) => {
            this.toast_success("Success", "เพิ่ม Note แล้ว", "success");
            this.note = null;
            this.load_data();
          })
          .catch((error) => {
            this.toast_error("Error", error, "danger");
          });
      } catch (error) {
        console.error("Error adding note:", error);
      }
    },
    toReadableDateWithGMT7(value) {
      if (value) {
        return moment(value).utcOffset(7).format("DD-MM-YYYY HH:mm");
      }
      return "";
    },
  },
};
</script>
<style scoped>
.no-hover-bg:hover {
  background-color: transparent !important;
}
.button-container {
  /* display: flex;
  justify-content: flex-end; */
  margin: 15px; /* Adjust spacing as needed */
}
</style>
