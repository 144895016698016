<template>
  <div>
    <b-modal
      id="modal-edit-member"
      ref="modal-edit-member"
      title="แก้ไขข้อมูลสมาชิก"
      no-close-on-backdrop
      hide-footer
    >
      <validation-observer ref="editMember">
        <b-form @submit.prevent="editMember">
          <!-- ชื่อ -->
          <b-form-group label="ชื่อ">
            <validation-provider
              #default="{ errors }"
              name="First name"
              :rules="{ required: true, regex: /^[ก-ฮะ-์ํฯ\s]+$/ }"
            >
              <b-form-input
                v-model="form.first_name"
                :state="errors.length > 0 ? false : null"
                placeholder="ภาษาไทยเท่านั้น"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- นามสกุล -->
          <b-form-group label="นามสกุล">
            <validation-provider
              #default="{ errors }"
              name="Last name"
              :rules="{ required: true, regex: /^[ก-ฮะ-์ํฯ\s]+$/ }"
            >
              <b-form-input
                v-model="form.last_name"
                :state="errors.length > 0 ? false : null"
                placeholder="ภาษาไทยเท่านั้น"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Gen Username">
            <validation-provider #default="{ errors }" name="Gen Username">
              <b-form-input v-model="form.gen_username" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- /^[ก-ฮะ-์\s] -->

          <!-- เลขที่บัตรประชาชน -->
          <b-form-group label="เลขที่บัตรประชาชน">
            <validation-provider
              #default="{ errors }"
              name="Card ID"
              rules="digits:13"
            >
              <b-form-input
                v-model="form.card_id"
                :state="errors.length > 0 ? false : null"
                placeholder="เฉพาะตัวเลขไม่ต้องเว้นวรรค"
                maxlength="13"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- เบอร์โทร -->
          <b-form-group label="เบอร์โทรศัพท์มือถือ">
            <validation-provider #default="{ errors }" name="Phone">
              <b-form-input
                v-model="form.phone"
                :state="errors.length > 0 ? false : null"
                placeholder="ไม่ต้องใส่ขีด"
                maxlength="10"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Email -->
          <b-form-group label="Email">
            <validation-provider #default="{ errors }" name="Email">
              <b-form-input
                v-model="form.email"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- วันเกิด -->
          <b-form-group>
            <day-month-year
              :prop_select="birth_select"
              :day_text="'วันเกิด'"
              :year_range="{
                max_year: this_year - 18,
                min_year: this_year - 80,
              }"
              @PickerUpdate="BirthdayUpdate"
            >
            </day-month-year>
            <validation-provider #default="{ errors }" name="Birth Day">
              <b-form-input v-model="form.birth_day" class="hidden" />
              <small class="text-danger"
                ><div>{{ errors[0] }}</div></small
              >
            </validation-provider>
            <validation-provider #default="{ errors }" name="Birth Month">
              <b-form-input v-model="form.birth_month" class="hidden" />
              <small class="text-danger"
                ><div>{{ errors[0] }}</div></small
              >
            </validation-provider>
            <validation-provider #default="{ errors }" name="Birth Year">
              <b-form-input v-model="form.birth_year" class="hidden" />
              <small class="text-danger"
                ><div>{{ errors[0] }}</div></small
              >
            </validation-provider>
            <small
              >ผู้เช่าต้องมีอายุมากกว่า 18 ปีเท่านั้น ( ปีน้อยกว่า
              {{ this_year - 18 }} )</small
            >
          </b-form-group>

          <!-- เพศ -->
          <b-form-group label="เพศ" class="mb-2">
            <validation-provider #default="{ errors }" name="Gender">
              <div class="demo-inline-spacing my-0">
                <b-form-radio
                  v-model="form.gender"
                  name="some-radios"
                  value="male"
                  class="my-0"
                >
                  ชาย
                </b-form-radio>
                <b-form-radio
                  v-model="form.gender"
                  name="some-radios"
                  value="female"
                  class="my-0"
                >
                  หญิง
                </b-form-radio>
                <b-form-radio
                  v-model="form.gender"
                  name="some-radios2"
                  value="other"
                  class="my-0"
                >
                  อื่นๆ
                </b-form-radio>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ที่อยู่ ที่อยู่ปัจจุบัน-->
          <b-form-group label="ที่อยู่ปัจจุบัน">
            <validation-provider #default="{ errors }" name="Address">
              <b-form-input
                v-model="form.address1"
                :state="errors.length > 0 ? false : null"
                placeholder="เลขที่ อาคาร/หมู่บ้าน หมู่ ซอย"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ถนน -->
          <b-form-group label="ถนน">
            <validation-provider #default="{ errors }" name="Road">
              <b-form-input
                v-model="form.road"
                :state="errors.length > 0 ? false : null"
                placeholder="ถนน"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ที่อยู่ group -->
          <b-form-group>
            <p>กรอก <u>แขวง</u> หรือ <u>เขต</u> หรือ <u>รหัสไปรษณีย์</u></p>
            <address-selector @addressSelected="addressSelected">
            </address-selector>

            <b-alert
              show
              variant="success"
              v-if="form.zipcode != null"
              class="mt-1"
            >
              <div class="alert-body">{{ form.full_address }}</div>
            </b-alert>
            <b-alert v-else show variant="danger">
              <div class="alert-body">ยังไม่ได้กรอกข้อมูลที่อยู่</div>
            </b-alert>

            <validation-provider #default="{ errors }" name="Zipcode">
              <b-form-input v-model="form.zipcode" class="hidden" />
              <small class="text-danger">
                <div>{{ errors[0] }}</div>
              </small>
            </validation-provider>
          </b-form-group>
          <hr class="my-2" />

          <!-- วันหมดอายุบัตรประชาชน -->
          <b-form-group>
            <!-- <day-month-year
              :day_text="'วันหมดอายุบัตร'"
              :year_range="{ max_year: this_year + 15, min_year: this_year }"
              @PickerUpdate="IdExpireUpdate"
              :prop_select="id_ex_select"
            ></day-month-year> -->
            <!-- <validation-provider
              rules="required"
              #default="{ errors }"
              name="ID Expire Day"
            >
              <b-form-input v-model="form.id_ex_day" class="hidden" />
              <small class="text-danger"
                ><div>{{ errors[0] }}</div></small
              >
            </validation-provider>
            <validation-provider
              rules="required"
              #default="{ errors }"
              name="ID Expire Month"
            >
              <b-form-input v-model="form.id_ex_month" class="hidden" />
              <small class="text-danger"
                ><div>{{ errors[0] }}</div></small
              >
            </validation-provider>
            <validation-provider
              rules="required"
              #default="{ errors }"
              name="ID Expire Year"
            >
              <b-form-input v-model="form.id_ex_year" class="hidden" />
              <small class="text-danger"
                ><div>{{ errors[0] }}</div></small
              >
            </validation-provider> -->
          </b-form-group>

          <!-- NOTE -->
          <!-- <b-form-group label="NOTE">
            <b-form-group>
              <validation-provider
                                #default="{ errors }"
                                rules="required|digits:12"
                                name="laser1"
                            >
              <b-form-input
                v-model="form.id_laser"
                placeholder="XXX"
                maxlength="12"
                @input="moveToNextField($event, 2, 3)"
              />
              </validation-provider> :state="errors.length > 0 ? false:null"
            </b-form-group>
          </b-form-group> -->
          <b-form-group label="Note">
            <b-form-textarea id="event-description" v-model="form.note_admin" />
          </b-form-group>

          <!-- blackout -->
          <b-form-group label="แสดงราคามัดจำ">
            <validation-provider #default="{ errors }" name="blackout">
              <div class="demo-inline-spacing mt-0">
                <b-form-radio
                  v-model="form.deposit_blackout"
                  name="some-radios"
                  :value="false"
                >
                  ปกติ
                </b-form-radio>
                <b-form-radio
                  v-model="form.deposit_blackout"
                  name="some-radios"
                  :value="true"
                >
                  ไม่แสดง !!
                </b-form-radio>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- BLACKLIST -->
          <b-form-group label="BLACKLIST">
            <div class="demo-inline-spacing mt-0">
              <b-form-radio
                v-model="form.blacklist"
                name="some-radios11"
                :value="false"
              >
                ปกติ
              </b-form-radio>
              <b-form-radio
                v-model="form.blacklist"
                name="some-radios11"
                :value="true"
              >
                BLACKLIST
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- blackout -->
          <b-form-group label="ระดับ BLACKLIST" v-if="form.blacklist === true">
            <div class="demo-inline-spacing mt-0">
              <b-form-radio
                v-model="form.blacklist_level"
                name="some-radios22"
                value="red"
              >
                แดง
              </b-form-radio>
              <b-form-radio
                v-model="form.blacklist_level"
                name="some-radios22"
                value="yellow"
              >
                เหลือง
              </b-form-radio>
            </div>
          </b-form-group>

          <b-form-group label="Blacklist Note" v-if="form.blacklist === true">
            <b-form-textarea
              id="event-description2"
              v-model="form.blacklist_note"
            />
          </b-form-group>

          <hr />
          <b-button variant="success" @click.prevent="validationForm">
            แก้ไขข้อมูล
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BCard,
  BButton,
  BFormRadio,
  BAlert,
  BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AddressSelector from "./AddressSelector.vue";
import DayMonthYear from "./DayMonthYear.vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import vSelect from "vue-select";
import moment from "moment";

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    BFormRadio,
    ValidationObserver,
    ValidationProvider,
    AddressSelector,
    BAlert,
    vSelect,
    BRow,
    BCol,
    DayMonthYear,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  created() {
    this.LoadMember();
  },
  props: ["member_id"],
  data() {
    return {
      form: {
        address1: null,
        road: null,
        birth_day: null,
        birth_month: null,
        birth_year: null,
        district_code: null,
        id_ex_day: null,
        id_ex_month: null,
        id_ex_year: null,
        deposit_blackout: false,
        zipcode: null,
        blacklist_level: null,
        blacklist: false,
        blacklist_note: null,
      },
      this_year: new Date().getFullYear(),
      birth_select: {},
      id_ex_select: {},
    };
  },
  methods: {
    LoadMember() {
      this.$http({
        method: "GET",
        url: `/member/view/` + this.member_id,
        data: this.form,
      })
        .then((x) => {
          this.form = x.data.data;
          const birth_moment = moment(x.data.data.birth);
          const id_ex_moment = moment(x.data.data.id_ex);

          this.birth_select = {
            day: {
              name: "b_day",
              value: birth_moment.date(),
              title: birth_moment.date(),
            },
            month: {
              name: "b_month",
              value: birth_moment.month() + 1,
              title: birth_moment.month() + 1,
            },
            year: {
              name: "b_year",
              value: birth_moment.year(),
              title: birth_moment.year(),
            },
          };

          this.id_ex_select = {
            day: {
              name: "b_day",
              value: id_ex_moment.day(),
              title: id_ex_moment.day(),
            },
            month: {
              name: "b_month",
              value: id_ex_moment.month(),
              title: id_ex_moment.month(),
            },
            year: {
              name: "b_year",
              value: id_ex_moment.year(),
              title: id_ex_moment.year(),
            },
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "แก้ไขข้อมูลสมาชิก",
          icon,
          text: "แก้ไขข้อมูลสมาชิกเรียบร้อยแล้ว",
          variant,
        },
      });
    },
    EditMember() {
      this.$http({
        method: "PUT",
        url: `/member/${this.member_id}`,
        data: this.form,
      })
        .then((x) => {
          this.$refs["modal-edit-member"].hide();
          this.$root.$emit("edit-member-complete");
          this.showToast("success", "CheckIcon");
          this.LoadMember();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validationForm() {
      this.$refs.editMember.validate().then((success) => {
        if (success) {
          this.EditMember();
        }
      });
    },
    addressSelected(x) {
      this.form.amphoe_code = x.item.amphoe_code;
      this.form.amphoe = x.item.amphoe;

      this.form.district_code = x.item.district_code;
      this.form.district = x.item.district;

      this.form.province_code = x.item.province_code;
      this.form.province = x.item.province;
      this.form.zipcode = x.item.zipcode;
    },
    BirthdayUpdate(x) {
      console.log("birthday", x);
      const paddedDay = x.day.toString().padStart(2, "0");
      const paddedMonth = x.month.toString().padStart(2, "0");
      const year = x.year;
      this.form.birth = `${year}-${paddedMonth}-${paddedDay}T00:00:00.000+00:00`;
      // console.log("this.form.birth", this.form.birth);
      this.form.birth_day = x.day;
      this.form.birth_month = x.month;
      this.form.birth_year = x.year;
    },
    IdExpireUpdate(x) {
      this.form.id_ex_day = x.day;
      this.form.id_ex_month = x.month;
      this.form.id_ex_year = x.year;

      console.log("id ex", x);
    },
    validateField(field) {
      console.log(field);
      const provider = this.$refs[field];
      // Validate the field
      return provider.validate();
    },
    moveToNextField(e, num, limit) {
      console.log(e, e.length, "OKOKOKO", num, limit);
      if (e.length === limit && num === 2) {
        this.$refs.laser2.focus();
      }
      if (e.length === limit && num === 3) {
        this.$refs.laser3.focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
[dir] .demo-inline-spacing > * {
  margin-top: 0.5rem;
}
</style>
